/*============================================================
	For Small Desktop
==============================================================*/

@media (min-width: 980px) and (max-width: 1150px) {
   /* slider */
   .carousel-caption h3 {
      font-size: 45px;
   }

   /* deposits */

   /* team */

   .member-thumb {
      width: auto;
   }
}

/*============================================================
	Tablet (Portrait) Design for a width of 768px
==============================================================*/

@media (min-width: 768px) and (max-width: 979px) {
   /* slider */

   .carousel-caption h2 {
      font-size: 55px;
   }

   .carousel-caption h3 {
      font-size: 36px;
   }

   /* services */

   .service-item {
      margin: 0 auto 30px;
      text-align: center;
      width: 569px;
   }

   .service-icon {
      float: none;
      text-align: center;
      width: 50px;
   }

   .service-desc {
      margin-left: 0;
      position: relative;
      top: 0;
   }

   /* works */

   .work-item {
      width: 33.3333333%;
   }

   /* team */

   .member-thumb .overlay h5 {
      margin: 25px 0;
   }

   .member-thumb {
      margin: 0 auto;
   }

   /* fatcs */

   #facts {
      background-position: center top !important;
   }
   .counters-item {
      margin-bottom: 46px;
   }

   .counters-item i {
      margin: 0 0 15px;
   }

   .counters-item strong {
      font-size: 45px;
   }

   /* contact */

   .contact-form .name-email input {
      margin-right: 0;
      width: 100%;
   }

   .footer-social {
      margin-top: 45px;
   }

   /* footer */

   .footer-single {
      margin-bottom: 30px;
   }
}

/*============================================================
	Mobile (Portrait) Design for a width of 320px
==============================================================*/

@media only screen and (max-width: 767px) {
   .main-logo {
      height: 22vh;
      width: 22vh;
      -webkit-filter: drop-shadow(0 0 40px #f4e17f);
   }
   #slider .title-box {
      top: 50vh;
   }
   .btn-group {
      flex-direction: column;
   }
   .btn-group a,
   .btn-group button {
      margin: 5px;
   }
   #read-more,
   .btn-gold {
      width: 142px;
      font-size: 1em;
   }
   #table-winners {
      font-size: 0.9em;
   }
   .sec-sub-title p {
      font-size: 14px;
   }

   /* slider */
   .carousel-caption h2 {
      font-size: 35px;
   }

   .carousel-caption h3 {
      font-size: 22px;
   }

   .carousel-caption p {
      font-size: 14px;
   }

   .social-links {
      /* margin-top: 20%; */
   }

   /* services */

   .service-item {
      text-align: center;
      -webkit-box-shadow: 0 0 56px #ebd378;
      -moz-box-shadow: 0 0 56px #ebd378;
      box-shadow: 0 0 28px #ebd378;
      border-radius: 50px;
      padding: 8px;
   }
   .service-item-wrapper {
      border-radius: 50px;
   }
   .service-item-shadow {
      margin: 105px 0;
   }

   .service-icon {
      text-align: center;
   }
   .service-icon img {
      width: 196px;
      height: 196px;
   }

   .service-desc {
      margin-left: 0;
      position: relative;
      top: 0;
   }

   .service-desc h3 {
      font-size: 3.5rem;
   }

   /* works */

   .work-item {
      width: 100%;
   }

   /* team */

   .team-member {
      margin-bottom: 30px;
   }

   .team-member:last-child {
      margin-bottom: 0;
   }

   .member-thumb {
      margin: 0 auto;
   }

   /* facts */

   #facts {
      background-position: center top !important;
   }

   .counters-item {
      margin-bottom: 30px;
   }

   /* contact */
   .contact-address {
      margin-bottom: 30px;
   }

   .footer-social {
      margin-top: 20px;
      text-align: center;
   }

   .footer-social li {
      display: inline-block;
   }

   .footer-social li a {
      margin: 0 10px;
   }

   /* footer */

   .footer-single {
      margin-bottom: 30px;
   }

   a.navbar-brand {
      margin-top: 2px;
   }
   a.navbar-brand h1 {
      font-size: 23px;
   }
   #title .space {
      font-weight: 400;
   }

   .dep-form-wrap {
      display: flex;
      justify-content: center;
      margin-bottom: 2rem;
   }
}

/*============================================================
	Mobile (Landscape) Design for a width of 480px
==============================================================*/

@media only screen and (min-width: 480px) and (max-width: 767px) {
   /* services */

   .service-item {
      margin: 0 auto 30px;
      text-align: center;
      width: 100%;
      -webkit-box-shadow: 0 0 56px #ebd378;
      -moz-box-shadow: 0 0 56px #ebd378;
      box-shadow: 0 0 56px #ebd378;
      border-radius: 76px;
   }

   .service-icon {
      text-align: center;
   }

   .service-desc {
      margin-left: 0;
      position: relative;
      top: 0;
   }

   /* works */

   .work-item {
      width: 50%;
   }
}

@media only screen and (min-width: 480px) and (max-width: 540px) {
   .work-item {
      width: 100%;
   }
}
